import { $win, $body } from '../utils/globals'

let lastScrollPosition = null;

$('.js-nav-trigger').on('click', function(event) {
	event.preventDefault();

	const isMenuOpened = $body.is('.has-menu-opened');

	if (isMenuOpened) {
		$body.removeClass('has-menu-opened is-fixed');

		window.scrollTo({
			top: lastScrollPosition,
			left: 0,
			behavior: 'instant'
		});

		lastScrollPosition = null;
		$('.wrapper__inner').removeAttr('style');
		return
	} else {
		lastScrollPosition = $win.scrollTop();
		$body.addClass('is-fixed');
		$('.wrapper__inner').css({
			top: -lastScrollPosition
		});
	}

	$body.toggleClass('has-menu-opened');
});
