$('.js-toggle-more').on('click', function(event) {
	event.preventDefault();

  let visibleItemsCount = $(this).data('visibleItems');
  if (visibleItemsCount) {
    visibleItemsCount = parseInt(visibleItemsCount, 10);
  } else {
    visibleItemsCount = 10;
  }

  const btnLabel = $(this).text();
  $(this).text($(this).data('text'));
  $(this).data('text', btnLabel);

  $(this).closest('.rates').find('li:nth-child(n+' + (visibleItemsCount + 1) + ')').toggleClass('hidden');
});
