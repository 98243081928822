import { $win } from '../utils/globals';
import styles from './map-styles';
import {
  AUTOCOMPLETE_OPTIONS,
  DEFAULT_MAP_CENTER,
  ENTER_KEY_CODE,
} from './constants';
import {
  ERRORS_MAP,
  clearFieldError,
  addFieldError,
  validateField,
  FIELDS_ID_MAP,
} from './form-helpers';

const PHONE_CODE = '+359';

let startAddressAutocomplete, endAddressAutocomplete;
const mapContainer = $('#map .map__container')?.[0];
const markers = {
  start: null,
  end: null,
};

if ($('#map').length) {
  // Load google maps script
  const script = document.createElement('script');
  script.src = `https://maps.googleapis.com/maps/api/js?key=${window.$$context.googleMapsApiKey}&libraries=places&callback=onMapsApiLoaded`;
  script.async = true;
  window.onMapsApiLoaded = onMapsApiLoaded;
  window.onOrdersFormSubmit = onOrdersFormSubmit;
  document.head.appendChild(script);
}

function onMapsApiLoaded() {
  const address = {
    start: '',
    end: '',
  };
  const fields = {
    start: document.getElementById('field-start-address'),
    end: document.getElementById('field-end-address'),
  };
  const map = new google.maps.Map(mapContainer, {
    styles,
    center: DEFAULT_MAP_CENTER,
    zoom: 8,
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
  });

  for (const field in fields) {
    fields[field].addEventListener('blur', function () {
      fields[field].value = address[field];
    });
  }

  const polyline = new google.maps.Polyline({
    icons: [
      {
        icon: {
          path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        },
        offset: '100%',
      },
    ],
    map: map,
  });

  startAddressAutocomplete = new google.maps.places.Autocomplete(
    fields.start,
    AUTOCOMPLETE_OPTIONS,
  );
  endAddressAutocomplete = new google.maps.places.Autocomplete(
    fields.end,
    AUTOCOMPLETE_OPTIONS,
  );

  startAddressAutocomplete.addListener('place_changed', () => {
    const place = startAddressAutocomplete.getPlace();

    if (place.geometry) {
      addMarker(map, place.geometry.location, 'start');
      address.start = fields.start.value;
      clearFieldError('field-start-address');
    } else {
      removeMarker(map, 'start');
      fields.start.value = '';
      address.start = '';
    }
    polyline.setPath(
      [markers.start?.position, markers.end?.position].filter(Boolean),
    );
  });

  endAddressAutocomplete.addListener('place_changed', () => {
    const place = endAddressAutocomplete.getPlace();

    if (place.geometry) {
      addMarker(map, place.geometry.location, 'end');
      address.end = fields.end.value;
      clearFieldError('field-end-address');
    } else {
      removeMarker(map, 'end');
      fields.end.value = '';
      address.end = '';
    }
    polyline.setPath(
      [markers.start?.position, markers.end?.position].filter(Boolean),
    );
  });

  $(
    'input[name="field-start-address"], input[name="field-end-address"], input[name="field-mobile-phone"]',
  ).on('input', (event) => {
    clearFieldError(event.target.id);
  });

  $('.js-map').on('submit', validate);
  $('.js-map form input[type="submit"]').on('click', validate);
}

/**
 * @param {SubmitEvent} event
 */
function validate(event) {
  event.preventDefault();

  const { 'field-mobile-phone': phoneField } = event.target.elements;

  const fieldsToValidate = {
    startAddress: startAddressAutocomplete.getPlace(),
    endAddress: endAddressAutocomplete.getPlace(),
    phone: phoneField.value,
  };

  let isValid = true;
  clearFieldError(FIELDS_ID_MAP['generic']);
  for (let [fieldName, fieldValue] of Object.entries(fieldsToValidate)) {
    clearFieldError(FIELDS_ID_MAP[fieldName]);
    if (!validateField(fieldName, fieldValue)) {
      isValid = false;
      addFieldError(fieldName, ERRORS_MAP[fieldName]);
    }
  }

  if (isValid) {
    grecaptcha.execute();
  }
}

/**
 * @param {google.maps.Map} map
 * @param {{lat: number, lng: number}} position
 * @param {string} type
 */
function addMarker(map, position, type) {
  if (markers[type]) {
    markers[type].setPosition(position);
  } else {
    const iconName = type === 'start' ? 'markerGreen' : 'markerGold';
    markers[type] = new google.maps.Marker({
      map: map,
      position,
      icon: {
        url: mapContainer.dataset[iconName],
      },
    });
  }

  setBounds(map);
}

/**
 * @param {google.maps.Map} map
 * @param {string} type
 */
function removeMarker(map, type) {
  markers[type]?.setMap(null);
  markers[type] = null;

  setBounds(map);
}

/**
 * @param {google.maps.Map} map
 */
function setBounds(map) {
  const bounds = new google.maps.LatLngBounds();

  if (markers.start) {
    bounds.extend(markers.start.position);
  }

  if (markers.end) {
    bounds.extend(markers.end.position);
  }

  let boundingOffset = 620;
  if ($win.width() > 1800) {
    boundingOffset = ($win.width() - 1640) / 2 + 520;
  }

  if ($win.width() < 1200) {
    boundingOffset = 400;
  }

  if ($win.width() < 768) {
    boundingOffset = 50;
  }

  if (!bounds.isEmpty()) {
    map.fitBounds(bounds, {
      left: boundingOffset,
      top: 50,
      bottom: 50,
      right: 50,
    });
  }
}

/**
 * @param {String} token
 */
function onOrdersFormSubmit(token) {
  /**
   * @type {HTMLFormElement}
   */
  const formEl = document.querySelector('.section-map form');

  const formData = new FormData();

  const {
    'field-plate-number': regNumField,
    'field-mobile-phone': phoneField,
    'field-location-description': messageField,
  } = formEl?.elements;

  const startAddress = startAddressAutocomplete?.getPlace();
  formData.append('startAddress[address]', startAddress?.formatted_address);
  formData.append('startAddress[lat]', startAddress?.geometry?.location.lat());
  formData.append('startAddress[lng]', startAddress?.geometry?.location.lng());

  const endAddress = endAddressAutocomplete?.getPlace();
  formData.append('endAddress[address]', endAddress?.formatted_address);
  formData.append('endAddress[lat]', endAddress?.geometry?.location.lat());
  formData.append('endAddress[lng]', endAddress?.geometry?.location.lng());

  formData.append('grecaptcha', token);
  formData.append('regNum', regNumField.value);
  formData.append('phone', `${PHONE_CODE}${phoneField.value}`);
  formData.append('message', messageField.value);

  formEl?.classList.add('is-loading');
  fetch(window.$$context.ajaxUrl + '?action=app_lucky_order', {
    method: 'POST',
    body: formData,
  })
    .finally(() => formEl?.classList.remove('is-loading'))
    .then((response) => response.json())
    .then((json) => {
      if (json.success) {
        $('.section-map .form').replaceWith(json.html);
      } else {
        grecaptcha.reset();
        for (const fieldName of Object.keys(json.errors)) {
          addFieldError(fieldName, json.errors[fieldName]);
        }
      }
    })
    .catch(() => {
      grecaptcha.reset();
    });
}

$(document).on(
  'keyup keypress',
  'form input[type="text"]',
  /**
   * @param {KeyboardEvent} e
   * @returns
   */
  function (e) {
    if (e.key == ENTER_KEY_CODE) {
      e.preventDefault();
      return false;
    }
  },
);
