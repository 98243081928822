export const FIELDS_ID_MAP = {
  startAddress: 'field-start-address',
  endAddress: 'field-end-address',
  phone: 'field-mobile-phone',
  regNum: 'field-plate-number',
  message: 'field-location-description',
  generic: 'recaptcha',
};

export const ERRORS_MAP = {
  startAddress: 'Невалиден адрес',
  endAddress: 'Невалиден адрес',
  phone: 'Невалиден телефонен номер',
};

/**
 * @param {String} fieldId
 */
export function clearFieldError(fieldId) {
  if (!Object.values(FIELDS_ID_MAP).includes(fieldId)) {
    return;
  }
  const $field = $(`#${fieldId}`);

  $field.parent().removeClass('is-invalid');
  $field.parent().find('.form__notice').remove();
}

/**
 * @param {String} fieldName
 * @param {String} message
 */
export function addFieldError(fieldName, message = '') {
  if (!Object.keys(FIELDS_ID_MAP).includes(fieldName)) {
    return;
  }

  const $field = $(`#${FIELDS_ID_MAP[fieldName]}`);
  $field.parent().addClass('is-invalid');

  if (message.length && !$field.next('.form__notice').length) {
    $field.parent().append(`<span class="form__notice">${message}</span>`);
  }
}

/**
 * @param {String} fieldName
 * @param value
 * @returns {Boolean}
 */
export function validateField(fieldName, value) {
  switch (fieldName) {
    case 'startAddress':
    case 'endAddress':
      return Boolean(value) && value?.geometry;
    case 'phone':
      return Boolean(value) && value.length === 9 && /^[0-9]+$/.test(value);
  }
}
