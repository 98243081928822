import { $win, $body } from '../utils/globals'

let winScrollTop = $win.scrollTop();

$win.on('load resize', function(){
	winScrollTop = $win.scrollTop();
})
.on('load scroll', () => {
	winScrollTop = $win.scrollTop();

	if ($('.js-scroll-trigger').length) {
		$body.toggleClass('has-fixed-header', winScrollTop > $('.js-scroll-trigger').height());
	}
});
