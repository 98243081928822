export const ENTER_KEY_CODE = 'Enter';

export const AUTOCOMPLETE_OPTIONS = {
  language: 'bg',
  fields: ['geometry', 'formatted_address', 'name'],
  componentRestrictions: { country: 'bg' },
};

export const DEFAULT_MAP_CENTER = {
  lat: 42.542423096811596,
  lng: 25.110057081146156,
};
